.rk__header {
    display: flex;
    flex-direction: row;
    margin-bottom: 15rem;
    height: 100%;
    width: 100%;
}

.rk__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
}

.rk__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.rk__header-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);

    margin-top: 1.5rem;
}

.rk__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rk__header-image img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1050px) {
    .rk__header {
        flex-direction: column;
    }

    .rk__header-content {
        margin: 5rem 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .rk__header-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .rk__header-content p {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .rk__header-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .rk__header-content p {
        font-size: 14px;
        line-height: 24px;
    }
}