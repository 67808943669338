.rk__skills{
    margin-bottom: 10rem;
}

.skills__head-text {
    font-family: var(--font-family);
    font-size: 2.75rem;
    font-weight: 800;
    text-align: center;
    color: var(--color-text);
    text-transform: capitalize;
    height: 100%;
    width: 100%;
}

@media screen and (min-width: 2000px) {
    .skills__head-text {
        font-size: 4rem;
        margin-top: 20rem;
    }
}

@media screen and (max-width: 450px) {
    .skills__head-text {
        font-size: 2rem;
        margin-top: 5rem;
    }
}

.skills__about {
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.skills__profiles {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;
}

.skills__profile-item {
    width: 190px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin: 2rem;


}

.skills__profile-item img {
    width: 100%;
    height: 90px;
    border-radius: 15px;
    object-fit: scale-down;
}

@media screen and (min-width: 2000px) {
    .skills__profile-item {
        width: 370px;
        margin: 2rem 4rem;
    }

    .skills__profile-item img {
        height: 320px;
    }
}

.skills__bold-text {
    font-family: var(--font-family);
    font-size: 30px;
    font-weight: 600;
    color: white;
    text-align: left;
}

@media screen and (min-width: 2000px) {
    .skills__bold-text {
        font-size: 2rem;
    }
}

@media screen and (max-width: 450px) {
    .skills__bold-text {
        font-size: 0.9rem;
    }
}

.skills__p-text {
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 17px;
    line-height: 28px;
    color: var(--color-text);

    margin-top: 1.5rem;

}

@media screen and (min-width: 2000px) {
    .skills__p-text {
        font-size: 1.75rem;
    }
}


